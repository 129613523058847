<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card v-if="!allInfoLoaded">
            <v-skeleton-loader type="card, list-item-two-line, actions" width="350"></v-skeleton-loader>
        </v-card>
        <div v-else>
            <v-card width="350">
                <v-card-title class="justify-center align-center nuvolos-header">
                    <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
                </v-card-title>
                <template v-if="validInvGen">
                    <h2 class="secondary--text text-center text-h6 mt-5">Join Nuvolos</h2>
                    <v-card-text>
                        <div v-if="!$auth.isAuthenticated" class="text-center">
                            <router-view />
                        </div>
                        <div class="text-caption mt-6">
                            By continuing, you agree to Nuvolos's
                            <a href="https://nuvolos.cloud/legal" target="_blank">Terms of Service</a>
                            and
                            <a href="https://nuvolos.cloud/privacy-policy" target="_blank">Privacy Policy</a>
                            , and to receiving emails with updates.
                        </div>
                    </v-card-text>
                </template>
                <template v-else>
                    <v-card-text class="font-weight-bold text-center pt-4">
                        <v-alert dense text type="error" class="ma-0">{{ errorContent }}</v-alert>
                    </v-card-text>
                </template>
            </v-card>
        </div>
    </div>
</template>

<script>
import { isDateGreaterThanToday } from '@/utils'

export default {
    name: 'SignupIndex',
    data() {
        return {
            orgName: '',
            spaceName: '',
            errorContent: 'Invalid link encountered, please check if you have the right invitation link.',
            loadingInvitation: true,
            validInvGen: true,
            generatingInvitation: true
        }
    },
    created() {
        this.getInvitationData()
    },
    methods: {
        async getInvitationData() {
            try {
                const { data } = await this.$axios.get(`/invitations/invgen/${this.$route.params.token}`)
                this.email_domain_whitelist = data.email_domain_whitelist
                this.orgName = data.org_long_id
                this.spaceName = data.space_long_id
                if (!data.is_valid && !isDateGreaterThanToday(data.validity_timestamp)) {
                    this.validInvGen = false
                    this.errorContent = 'This invitation generator has expired. Please contact your lecturer or TA to get a valid invitation'
                } else if (!data.is_valid) {
                    this.validInvGen = false
                    this.errorContent = 'This invitation generator has been exhausted. Please contact your lecturer or TA to get an invitation'
                }
            } catch (error) {
                this.validInvGen = false
                if (error.response?.data?.code === 'invitation_not_exists') {
                    this.errorContent = 'This invitation does not exist. Please check if link was correctly copied.'
                }
            } finally {
                this.loadingInvitation = false
            }
        },
        async generateInvitation() {
            try {
                const { data } = await this.$axios.post(`/invitations/invgen/${this.$route.params.token}`, {
                    email: this.$auth.user.email,
                    g_recaptcha_response: ''
                })
                const urlParams = new URL(data.url).pathname.split('/')
                const invitationId = urlParams[urlParams.length - 1]
                this.$router.push({
                    name: 'invitation-authenticated',
                    params: { invid: invitationId }
                })
                return data
            } catch (error) {
                this.errorContent = 'Could not get invitation, please try again later.'
                return error
            }
        }
    },
    computed: {
        allInfoLoaded() {
            return !this.loadingInvitation && !this.generatingInvitation && !this.$auth.loading
        }
    },
    watch: {
        '$auth.isAuthenticated': {
            handler(authenticated) {
                if (authenticated) {
                    this.generateInvitation()
                } else {
                    this.generatingInvitation = false
                }
            },
            immediate: true
        }
    },
    updated() {}
}
</script>

<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
